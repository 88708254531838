import React from 'react'
import { Grid } from '@material-ui/core'
import styled from 'styled-components'

import Aider1 from '../../images/aider1.png'
import Aider2 from '../../images/aider2.png'
import Aider3 from '../../images/aider3.png'
import Aider4 from '../../images/aider4.png'
import Aider5 from '../../images/aider5.png'

const ServicesGrid = styled(Grid)`
    border-top: 1px solid lightgrey;
    display: flex;
    justify-content: center;
    max-width: 90%;
    margin: 0 auto;
    padding-top: 50px;
    text-align: center;

    h2 {
        color: #3fa9f5;
        padding-bottom: 5px;
    }
    .subtitle {
        display: inline-block;
        font-size: 24px;
        font-weight: 100;
        padding-bottom: 30px;
    }
    h3 {
        font-weight: 600;
    }
    img {
        height: 70px;
    }
`

const Service = ({ img, title, description }) => (
    <Grid item xs={6} md={2}>
        <img src={img} alt={title} />
        <h3>{title}</h3>
        <span>{description}</span>
    </Grid>
)

const HelpServices = () => {
    return (
        <ServicesGrid container spacing={2}>
            <Grid item xs={12}>
                <h2>NOS SERVICES</h2>
                <span className="subtitle">
                    Comment nous pouvons <strong>vous aider</strong>
                </span>
            </Grid>
            <Service
                img={Aider1}
                title="Droit familial"
                description="Divorce/Séparation Garde/Pension"
            />
            <Service
                img={Aider2}
                title="Droit civil"
                description="Responsabilité Assurance"
            />
            <Service
                img={Aider3}
                title="Droit administratif"
                description="TAQ/CSST/SAAQ"
            />
            <Service
                img={Aider4}
                title="Droit immobilier"
                description="Vente/Achat/Baux Vice cache/Litige"
            />
            <Service
                img={Aider5}
                title="Droit corporatif"
                description="Convention d’actionnaire Constitution de compagnie"
            />
        </ServicesGrid>
    )
}

export default HelpServices
