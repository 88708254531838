import * as React from 'react'
import { Grid } from '@material-ui/core'
import Main from '../styles/LandingPages'
import Form from '../../../src/containers/LandingForm'
import Recommended from '../images/recommended.png'
import CreepyDude from '../images/top-man.png'
import Layout from '../components/Layout'
import Badges from '../components/Badges'
import HelpServices from '../components/HelpServices'
import Steps from '../components/Steps'
import Benefits from '../components/Benefits'
import UpperForm from '../components/UpperForm'
import BottomForm from '../components/BottomForm'
import BottomPopup from '../components/BottomPopup'
import { graphql } from 'gatsby'

// markup

const IndexPage = ({ location }) => {
    return (
        <Layout location={location}>
            <Main backgroundColor={'#ffffff'}>
                <title>
                    Comparez Tarifs & Honoraires d'Avocats & Services Légaux à
                    Québec, Montréal - Soumissions Avocat
                </title>
                <UpperForm homepage={true} />
                <Badges marginTop="-150px" big={true} />
                <HelpServices />
                <Steps />
                <Benefits />
                <BottomForm />
            </Main>
            <BottomPopup />
        </Layout>
    )
}

export default IndexPage

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
