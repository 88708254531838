import React from 'react'
import { Grid } from '@material-ui/core'
import styled from 'styled-components'

import Step1 from '../../images/step1.jpeg'
import Step2 from '../../images/step2.jpeg'
import Step3 from '../../images/step3.jpeg'

const StepsGrid = styled(Grid)`
    justify-content: center;
    margin: 0 auto;
    max-width: 90%;
    padding: 50px 0;
    text-align: center;
    h2 {
        color: #3fa9f5;
        padding-bottom: 5px;
    }
    .subtitle {
        display: inline-block;
        font-size: 24px;
        font-weight: 100;
        padding-bottom: 30px;
    }
    h3 {
        color: #3fa9f5;
        font-weight: 600;
    }
    img {
        width: 350px;
        @media (max-width: 667px) {
            width: 250px;
        }
    }
`

const Step = ({ img, title, description }) => (
    <Grid item xs={12} md={4}>
        <img src={img} alt={title} />
        <h3>{title}</h3>
        <span>{description}</span>
    </Grid>
)

const Steps = () => (
    <StepsGrid container spacing={4}>
        <Grid item xs={12}>
            <h2>COMMENT ÇA MARCHE</h2>
            <span className="subtitle">
                Aussi <strong>Simple Que 1-2-3</strong>
            </span>
        </Grid>
        <Step
            img={Step1}
            title="Étape 1"
            description="Remplissez le formulaire de demande en ligne en moins de deux."
        />
        <Step
            img={Step2}
            title="Étape 2"
            description="Obtenez une soumission dans les 48h suivant votre demande."
        />
        <Step
            img={Step3}
            title="Étape 3"
            description="Si l’offre vous convient, concluez l’entente avec votre avocat."
        />
    </StepsGrid>
)

export default Steps
