import React from 'react'
import { Grid } from '@material-ui/core'
import styled from 'styled-components'

import Benefit1 from '../../images/advantage1.png'
import Benefit2 from '../../images/advantage2.png'
import Benefit3 from '../../images/advantage3.png'
import Benefit4 from '../../images/advantage4.png'

const BenefitsGrid = styled(Grid)`
    background-color: #0c3562;
    color: white;
    justify-content: center;
    margin: 0 auto;
    max-width: 100%;
    padding: 150px 50px;
    text-align: center;
    h2 {
        color: #3fa9f5;
        padding-bottom: 5px;
    }
    .subtitle {
        display: inline-block;
        font-size: 24px;
        font-weight: 100;
        padding-bottom: 30px;
    }
    h3 {
        color: #3fa9f5;
        font-weight: 600;
    }
    .img-container {
        height: 70px;
        max-width: 120px;
        margin: 10px auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`

const Benefit = ({ img, title, description }) => (
    <Grid item xs={12} md={3}>
        <div className="img-container">
            <img src={img} alt={title} />
        </div>
        <h3>{title}</h3>
        <span>{description}</span>
    </Grid>
)

const Benefits = () => (
    <BenefitsGrid container spacing={4}>
        <Grid item xs={12}>
            <h2>Avantages</h2>
            <span className="subtitle">
                Un Service <strong>Avantageux à tous les niveaux</strong>
            </span>
        </Grid>
        <Benefit
            img={Benefit1}
            title="Gratuit"
            description="Pour le client pour le service de mise en relation"
        />
        <Benefit
            img={Benefit2}
            title="Sans engagement"
            description="Faire une demande ne vous oblige à rien"
        />
        <Benefit
            img={Benefit3}
            title="Rapide"
            description="Quelques clics Réponse en 48h"
        />
        <Benefit
            img={Benefit4}
            title="Efficace"
            description="Un avocat compétent à votre service"
        />
    </BenefitsGrid>
)

export default Benefits
